@import "../styles/variables.scss";

.sf-animation-spinner::before {
	animation-name: sf_animation_spinner;
  animation-duration: 1.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
@keyframes sf_animation_spinner {
	from {transform: rotateZ(0deg);}
	to {transform: rotateZ(359deg);}
}
@keyframes sf_animation_fade {
	0% {transform: scale(0.1);opacity: 0.1;}
	50% {transform: scale(1);opacity: 0.8;}
	100% {transform: scale(0.1);opacity: 0.1;}
}
#load_melting_circles{
	width: 100%; 
	display: flex;
	position: relative;
	flex-wrap: wrap-reverse;
}
.load_melting_circles{
	margin-bottom: 7px;
	background-color: var(--content-background);
	float:left;
	height:5px;
	margin-left:3px;
	width:5px;
	animation-name:bounce_load_melting_circles;
		-o-animation-name:bounce_load_melting_circles;
		-ms-animation-name:bounce_load_melting_circles;
		-webkit-animation-name:bounce_load_melting_circles;
		-moz-animation-name:bounce_load_melting_circles;
	animation-duration:2.24s;
		-o-animation-duration:2.24s;
		-ms-animation-duration:2.24s;
		-webkit-animation-duration:2.24s;
		-moz-animation-duration:2.24s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	border-radius:20px;
		-o-border-radius:20px;
		-ms-border-radius:20px;
		-webkit-border-radius:20px;
		-moz-border-radius:20px;
}
#load_melting_circles_1{
	animation-delay:0.45s;
		-o-animation-delay:0.45s;
		-ms-animation-delay:0.45s;
		-webkit-animation-delay:0.45s;
		-moz-animation-delay:0.45s;
}
#load_melting_circles_2{
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}
#load_melting_circles_3{
	animation-delay:1.35s;
		-o-animation-delay:1.35s;
		-ms-animation-delay:1.35s;
		-webkit-animation-delay:1.35s;
		-moz-animation-delay:1.35s;
}
@keyframes bounce_load_melting_circles{
	0%{}
	50%{
		background-color: var(--text-color);
	}
	100%{}
}
@-o-keyframes bounce_load_melting_circles{
	0%{}
	50%{
		background-color: var(--text-color);
	}
	100%{}
}
@-ms-keyframes bounce_load_melting_circles{
	0%{}
	50%{
		background-color: var(--text-color);
	}
	100%{}
}
@-webkit-keyframes bounce_load_melting_circles{
	0%{}
	50%{
		background-color: var(--text-color);
	}
	100%{}
}
@-moz-keyframes bounce_load_melting_circles{
	0%{}
	50%{
		background-color: var(--text-color);
	}
	100%{}
}
