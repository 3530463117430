@import "../styles/variables.scss";

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  &__loading {
    opacity: 0.85;
    pointer-events: none;
  }
}
.block-theme {
  display: flex;
  &__check {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 8px;
    margin-right: 15px;
    cursor: pointer;
    border: none;
    &--blue {
      background-color: $base-color-main-2;
      &:focus {
        border-color: $base-color-main-2;
      }
    }
    &--burgundy {
      background-color: $base-color-main-3;
      &:focus {
        border-color: $base-color-main-3;
      }
    }
    &--gray {
      background-color: $base-color-main-1;
      &:focus {
        border-color: $base-color-main-1;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &__active {
    position: relative;
    background-image: url(../assets/icons/check.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -60%);
    -ms-transform: translate(-50%, -60%);
    text-align: center;
    width: 25px;
    height: 25px;
  }
  &__text {
    margin: 0 8px 0 8px;
  }
}
.block-img {
  display: block;
  max-width: 180px;
  padding: 0;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  &__image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 8px;
    object-fit: cover;
    &--with-border {
      border: 2px dashed rgb(151, 151, 151);
    }
    &--with-image {
      color: var(--btn-contour-color);
      border: 2px solid rgb(151, 151, 151);
    }
  }
  &__text-load {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  &__text {
    color: var(--btn-contour-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgb(40, 40, 40);
  border-radius: 8px;
}
.block-img:hover .overlay {
  opacity: 0.8;
}

.App-title {
  color: var(--text-color);
  text-align: left;
  font-weight: 500;
  @include fontSize(14px, 19px, 480px, 1280px, 18px);
  margin-left: 8px;
}
.container {
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container > .content-wrap {
  // display: block;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  text-align: left;
}
.container-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.container-flex-filter {
  display: flex;
  align-items: center;
}
.container-flex-end {
  display: flex;
  justify-content: flex-end;
}
.container > .content-wrap {
  width: auto;
	min-width: 320px;
}
.App-navigation {
  background: var(--base-background);
  height: 42px;
}
.App-header-name {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
}
.container-logo {
  display: flex;
  align-items: center;
}
.block-header {
  // font-size: 18px;
  @include fontSize(14px, 28px, 480px, 1280px, 16px);
}
.App-logo {
  pointer-events: none;
  background-position: top center;
  background-size: cover;
  max-height: 40px;
  max-width: 110px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  margin-top: 12px;
  margin-right: 8px;
}
.slide-img {
  &__Map-img {
    background-position: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    // background-image: url(../assets/img/map_uzb.svg);
    &--no-data {
      -webkit-filter: grayscale(1); 
      filter: grayscale(1); 
    }
  }
  &__Pie-img {
    background-position: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/img/pie_chart.svg);
    &--no-data {
      -webkit-filter: grayscale(1); 
      filter: grayscale(1); 
    }
  }
  &__Area-img {
    background-position: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/img/area_chart.svg);
    &--no-data {
      -webkit-filter: grayscale(1); 
      filter: grayscale(1); 
    }
  }
  &__Bar-img {
    background-position: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/img/bar_chart.svg);
    &--no-data {
      -webkit-filter: grayscale(1); 
      filter: grayscale(1); 
    }
  }
}
.App-header {
  position: relative;
  background: var(--header-background);
  z-index: 10;
  box-shadow: 0 3px 3px -3px rgb(0 0 0 / 50%);
  position: fixed;
  top: 0;
  width: 100%;
}
.container {
  margin-left: auto;
  margin-right: auto;
}
.container {
	// width: $container-size;
	> .content-wrap {
		width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: $height-app-header;
	}
}
.App-link {
  color: #61dafb;
}
.minus-increment {
  color: var(--accent-text);
}
.small-text {
  font-size: 12px;
}
table {
  margin: 20px 0 30px;
  border-collapse: collapse;
  @include fontSize(12px, 15px, 480px, 1280px, 12px);
}
.flex-block-space {
  display: flex;
  justify-content: space-between;
}
thead {
  text-align: left;
}
.datagrid-table {
  background-color: transparent;
  color: #333;
  line-height: inherit;
  width: 100%;
}
.container-block-admin {
  background-color: var(--head-background) !important;
  margin-top: 15px;
}
table, tr td, th {
  padding: 15px;
  word-wrap: break-word;
}
table.DarkTable.datagrid-table tr, table.DarkTable.datagrid-table th {
  border-bottom: 2px solid var(--line-color);
}
table.MainTable.datagrid-table tr,  table.MainTable.datagrid-table td, table.MainTable.datagrid-table th {
  border: 1px solid var(--line-color);
}
table tr {
  background: var(--content-background) !important;
}
.tr-sum {
  background: var(--accent-background) !important;
}
table tr:nth-child(even) {
  // background: #e1e0e0  !important;
}
.container-menu {
  display: flex;
  background: var(--header-background);
  align-items: center;
}
.link-menu {
  text-align: center;
  position: relative;
  cursor: pointer;
  height: 54px;
  margin-left: 10px;
}
.icon-user {
  display: inline-block;
  margin-top: 8px;
}
// .DropdownMenu li {
//   border-bottom: 1px solid #dddddd;
//   border-top: 1px solid #dddddd;
// }
table th {
  background: var(--head-background) !important;
}
table tr td, table td > span, table tr > span {
  color: var(--text-color-table);
}
th, table th > span ftable tr td, th{
  color: var(--text-color-table-head)
}
.DropdownMenu {
  width: 180px;
  z-index: 1500;
  position: absolute;
  top: 72px; 
  right: 0;
  background: var(--block-background);
  padding: 0 15px 8px 15px;
  text-align: left;
  border-radius: 8px;
  box-shadow: var(--block-shadow);
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}
.DropdownMenu ul, .SubMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
span.link {
  @include fontSize(14px, 14px, 480px, 1280px, 14px);
  color: var(--text-color);
  cursor: pointer;
  &:hover {
    color: var(--base-color);
  }
}
button, input, optgroup, select, textarea, div {
  margin: 0;
  font: inherit;
  color: var(--text-color);
  &:focus {
    border: 1px solid var(--link-color);
  }
  &:focus-visible {
    outline: none;
  }
}
.container-fluid {
  // padding-right: 15px;
  // margin-right: 20px;
}
.checkbox, .radio {
  position: relative;
  display: grid;
  // margin-top: 10px;
  margin-bottom: 10px;
}
label {
  @include fontSize(12px, 16px, 480px, 1280px, 14px);
  color: var(--text-color);
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  // font-weight: 700;
  color: var(--text-color);
}
.form-control {
  &__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 6px 12px;
    // font-size: 14px;
    @include fontSize(12px, 16px, 480px, 1280px, 14px);
    line-height: 1.42857143;
    color: var(--text-color);
    background-color: var(--accent-background);
    background-image: none;
    border: var(--light-border);
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    &--error {
      border: 1px solid $red-color;
    }
  }
}
.form-group {
  margin-bottom: 8px;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: baseline;
}
.form-filter {
  position: relative;
}
//
.login-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.login-wrap-error {
  display: block;
  width: 100%;
  color: $red-color;
  text-align: center;
}
.tile {
  background-color: var(--block-background);
  border: 1px solid var(--line-color);
  border-radius: 8px;
}
.tile-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.tile-login {
  display: flex;
  flex-direction: column;
  width: 368px;
  padding: 30px;
  box-shadow: 0 25px 53px rgb(0 0 0 / 25%);
}
.not-found {
  text-align: center;
  margin: 50px;
  color: $base-color;
  span {
    font-size: 150px;
  }
}
.box-setting {
  display: flex;
}
.table-setting {
  color: var(--link-color);
  cursor: pointer;
  flex: 1 1 auto;
  margin: 0 5px;
}
.block-link {
  color: var(--link-color);
  cursor: pointer;
  margin: 8px 0 8px 0;
}
.checkbox label, .radio label {
  min-height: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}
input[type=checkbox], input[type=radio] {
  margin: 8px 8px 0 0;
  margin-top: 1px\9;
  line-height: normal;
  :hover {
    background-color: #333;
  }
}
.checkbox-inline input {
  position: relative;
  z-index: -9999;
}
.checkbox-inline span {
  width: 20px;
  height: 20px;
  display: block;
  background-color: #61dafb;
}

.checkbox-inline input:checked + span {
  background-color: #61dafb;
}

.flex-column {
  display: flex;
  margin: 0 -15px;
  &__item {
    flex: 1 1 auto;
    margin: 0 15px;
  }
}
.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
h1.map {
  margin-top: 15px;
}
h1.chart3 {
  position: absolute;
  top: 0;
  right: 0;
}
#myChart3 {
  position: absolute;
  top: 36px;
  right: 0;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}
.legend {
  line-height: 18px;
  color: #555;
}
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
.boxShadow, .boxShadow8, .boxShadow7, .boxShadow6, .boxShadow-preview {
  background: var(--block-background);
}
.boxShadow8 {
  /* width: 80%;
  max-width: 550px; */
  margin: 2em auto;
  padding: 1em;
  box-shadow: var(--block-shadow);
  margin: 12px;
}
.boxShadow7 {
  margin: 2em auto;
  padding: 1em;
  box-shadow: var(--block-shadow);
  margin: 20px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 92%;
}
.boxShadow6 {
  margin: 2em auto;
  padding: 1em;
  box-shadow: var(--block-shadow);
  margin: 20px;
  top: 0;
  left: 0;
  width: 58%;
  height: 94%;
}
footer {
  background: var(--base-background);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  z-index: 500;
}
////slider
.slider {
    position: relative;
    width: 100%;
    height: 100%;
    // margin: auto;
    box-sizing: border-box;
    align-items: center;
    // overflow: hidden;
}
.slider-img {
    position: absolute;
    width: 100%;
    // height: 500px;
    transition: all 0.2s;
}
 
.slider-img-prev {
    transform: translateX(-100%);
}
 
.slider-img-next {
    transform: translateX(100%);
}
.boxShadow {
  padding: 1em;
  box-shadow: var(--block-shadow);
  margin: 20px 0 40px 20px;
  top: 0;
  left: 0;
  // height: 768px;
  height: calc(100vh - 212px); //было 230 200 and 30 footer
  border-radius: 10px;
  overflow: hidden;
}
.boxShadow::-webkit-scrollbar {
  width: 12px;              
}
.boxShadow::-webkit-scrollbar-track {
  background: #eeeeee;    
}
.boxShadow::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;    
  border-radius: 20px;       
  border: 3px solid var(--line-color);  
}
.boxShadow-preview {
  padding: 1em;
  box-shadow: var(--block-shadow);
  margin-top: 20px;
  top: 0;
  left: 0;
  width: calc(((100vh - 230px) / 3));
  height: calc(((100vh - 230px) / 3) - 40px);
  border-radius: 10px;
  cursor: pointer;
  display: block;
}
.boxShadow-button {
  padding: 0 1rem;
  margin-top: 20px;
  text-align: center;
  top: 0;
  left: 0;
  width: calc(((100vh - 230px) / 3));
  border-radius: 10px;
  cursor: pointer;
  display: block;
  border: 1px solid $base-color;
}
.btn-text {
  color: $base-color;
}
.container-preview {
  pointer-events: none;
  height: 100%;
  width: 100%;
}
.container-main {
  // height: 80%;
  width: 100%;
}
.label-top-chart {
  transform: translateY(-10px);
}
.label-chart, .label-top-chart {
  fill: var(--text-color);
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
	background: #ffffc4 !important;
	box-shadow: 0 3px 14px rgba(0,0,0,0.2) !important;
	opacity: 0.9 !important;
  color: var(--text-color) !important;
}
.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.leaflet-control {
  z-index: 0 !important;
}
.dateUpdate {
  &__container {
    @include fontSize(12px, 14px, 480px, 1280px, 14px);
    color: $text-color-gray;
    right: 0;
    bottom: 0;
    padding-bottom: 32px;
    padding-right: 30px;
    display: flex;
    position: fixed;
    width: 100%;
    background: var(--body-background);
    justify-content: flex-end;
    z-index: 500;
  }
}

.copyright, .copyright span {
  color: $light-color;
  font-size: 12px;
  margin-top: 5px;
}
.copyright .text {
  margin-right: 5px;
}
.leaflet-right {
  right: -10px !important;
}
.recharts-text.recharts-pie-label-text {
  @include fontSize(36px, 30px, 480px, 1280px, 30px);
}
.icon-setting {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.block-fixed {
  position: sticky;
    // width: 100%;
    z-index: 2;
    background: var(--body-background);
    top: 110px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.slide-block-fixed {
  position: sticky;
    // width: 100%;
    z-index: 2;
    // background: #eeeeee;
    top: -24px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.block-scroll-map {
  overflow: auto;
  height: calc(100vh - 292px);
  @include medium {
    height: calc(100vh - 292px);
}
@include large {
  height: calc(100vh - 310px);
}
@include small {
  height: calc(100vh - 285px);
}
@include xsmall {
  height: calc(100vh - 340px);
}
@include xlarge {
  height: calc(100vh - 320px);
}
}
.block-scroll-map-page {
  overflow: auto;
  height: calc(100vh - 10px);
}
.block-scroll {
  overflow: auto;
  height: calc(100% - 10px);
}
.block-pre {
  height: 100%;
}
.block-scroll::-webkit-scrollbar {
  width: 12px;              
}
.block-scroll::-webkit-scrollbar-track {
  background: #eeeeee;    
}
.block-scroll::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;    
  border-radius: 20px;       
  border: 3px solid var(--line-color);  
}
.block-scroll-map::-webkit-scrollbar {
  width: 12px;              
}
.block-scroll-map::-webkit-scrollbar-track {
  background: #eeeeee;    
}
.block-scroll-map::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;    
  border-radius: 20px;       
  border: 3px solid var(--line-color);  
}
.block-scroll-map-page::-webkit-scrollbar {
  width: 12px;              
}
.block-scroll-map-page::-webkit-scrollbar-track {
  background: #eeeeee;    
}
.block-scroll-map-page::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;    
  border-radius: 20px;       
  border: 3px solid var(--line-color);  
}
.recharts-default-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: var(--content-background);
  box-shadow: var(--block-shadow);
  white-space: nowrap;
  border-radius: 8px;
}

.leaflet-touch .leaflet-bar a {
  background: var(--accent-background) !important;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none !important;
}
.link-del {
  font-size: 13px;
  text-decoration: underline dashed;
  cursor: pointer;
  &:hover {
    color: var(--accent-text);
  }
}