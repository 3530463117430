@import "../../../styles/variables.scss";

.Preloader {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    width: 100px;
    height: 100px;
    margin: 30px auto;
}
.Preloader > div {
    position: relative;
    width: 100%;
    height: 100%;
    background: #535353;
    transform: scale(0);
    transform-origin: center center;
    animation: Preloader-anim 2s infinite linear;
}
.Preloader > div:nth-of-type(1), 
.Preloader > div:nth-of-type(5), 
.Preloader > div:nth-of-type(9) {
    animation-delay: 0.4s;
}
.Preloader > div:nth-of-type(4), 
.Preloader > div:nth-of-type(8) {
    animation-delay: 0.2s;
}
.Preloader > div:nth-of-type(2), 
.Preloader > div:nth-of-type(6) {
    animation-delay: 0.6s;
}
.Preloader > div:nth-of-type(3) {
    animation-delay: 0.8s;
}
@keyframes Preloader-anim {
    0% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}