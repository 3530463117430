@import "../styles/variables.scss";

.btn {
    @include fontSize(13px, 15px, 480px, 1280px, 12px);
    border: none;
    border-radius: 8px;
    padding: 5px 20px;
    cursor: pointer;
    box-shadow: var(--block-shadow);
    &__primary {
      background: var(--btn-primary-bg);
      color: var(--btn-primary-color);
    }
    &__secondary {
      background: var(--btn-secondary-bg);
      color: var(--btn-secondary-color);
    }
    &__contour {
      background: var(--btn-contour-bg);
      color: var(--btn-contour-color);
      border: 1px solid var(--btn-contour-color);
    }
    &__contour-gray {
      background: var(--btn-contour-gray-bg);
      color: var(--btn-contour-gray-color);
      border: 1px solid var(--btn-contour-gray-color);
    }
  }
  
  .btn-filter {
    @include fontSize(12px, 16px, 480px, 1280px, 14px);
    background-repeat: repeat-x;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--content-background);
    border: var(--light-border);
    padding: 3px 10px;
  }
