$base-color: var(--theme-base-color); 
$base-color-main-3: #772422;
$base-color-main-2: #08c;
$base-color-main-1: #7d7878;
$light-color: #D9D6D6;
$light-gray:#A9ACB0;
$text-color: #303030;
$text-color-gray: #7B7D80;
$red-color: #D9534F;
$light-red: #f46b69;
$blue-color: #0071BC;
$gray-color: #535353;
$color_p_1: #8884D8; 
$color_p_2: #82C99D;
$color_p_3: #EABA55;
$color-orange: #DF6C19;

$box-shadow:  1px 1px 10px 2px rgba(24, 25, 28, 0.2);
$bg-gray: linear-gradient(to bottom, #747474 0, #A9A9A9 100%);
$light-transparent-bg: hsla(0,0%,100%,.8);
$dark-transparent-bg: hsla(0, 0%, 49%, 0.8);

// Media
$base-font-size: 16px;
$container-size: 1600px;
$left-column-size: 192px;
$content-size: 1065.77px;
$center-column-size: 650px;

$height-slider: 230px;
$height-app-header: 110px;

// MAIN THEME COLORS
$main-transparent-background: $light-transparent-bg;
$main-base-color: var(--theme-base-color);
$main-base-background: var(--theme-base-background);
$main-body-background: #eee;
$main-header-background: #ffffff;
$main-block-background: #eee;
$main-content-background: #f7f7f7;
$main-head-background: #f7f7f7;
$main-accent-background: #e4e6e7;
$main-line-color: #c4c4c4;
$main-link-color: $blue-color;
$main-text-color: $text-color;
$main-text-color-table: $text-color;
$main-text-color-table-head: $text-color;
$main-icon-arrow: url('../assets/icons/arrow.svg');
$main-block-shadow: $box-shadow;
$main-light-border: 1px solid #ccc;
$main-accent-text: $red-color;
$main-icon-start-color: #2C2C2C;
$main-icon-start-color-active: #D9534F;
$main-icon-filter-color: #2C2C2C;
//buttons
$main-btn-primary-bg: var(--theme-base-background);
$main-btn-primary-color: #ffffff;
$main-btn-secondary-bg: $bg-gray;
$main-btn-secondary-color: #ffffff;
$main-btn-contour-bg: #ffffff;
$main-btn-contour-color: var(--theme-base-color);
$main-btn-contour-gray-bg: #ffffff;
$main-btn-contour-gray-color:  $gray-color;

// DARK THEME COLORS
$dark-transparent-background: $dark-transparent-bg;
$dark-base-background: var(--theme-base-background-2);
$dark-base-color: $light-red;
$dark-body-background: #31333C;
$dark-header-background: #363841;
$dark-block-background: #3D3F48;
$dark-content-background: #3B3D46;
$dark-head-background: #3B3D46;
$dark-accent-background: #333540;
// #484C5B;
$dark-line-color: #404652;
$dark-link-color: #69B6EA;
$dark-text-color: $light-color;
$dark-text-color-table: $light-gray;
$dark-text-color-table-head: $light-color;
$dark-icon-arrow: url('../assets/icons/arrow-white.svg');
$dark-block-shadow: $box-shadow;
$dark-light-border: none;
$dark-accent-text: $light-red;
$dark-icon-start-color: #bdbdbd;
$dark-icon-start-color-active: var(--theme-hover-link-color);
$dark-icon-filter-color: #fff;
//buttons
$dark-btn-primary-bg: var(--theme-base-background-2);;
$dark-btn-primary-color: #ffffff;
$dark-btn-secondary-bg: $bg-gray;
$dark-btn-secondary-color: #ffffff;
$dark-btn-contour-bg: transparent;
$dark-btn-contour-color: var(--theme-hover-link-color);
$dark-btn-contour-gray-bg: transparent;
$dark-btn-contour-gray-color: $light-gray;

// $dark-block-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
  }
  
  @function calcFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);
  
    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;
  
    $b: $b + $units;
  
    @return calc( #{$k} * 100vw + #{$b} );
  }
@mixin fontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    @if ($fallback) {
      font-size: $fallback;
    }
    font-size: calcFontSize($f-min, $f-max, $w-min, $w-max, px);
  }

  @mixin xsmall {
    @media (max-width: 599.99px) {
        @content;
    }
}
@mixin small {
    @media (min-width: 600px) and (max-width: 959.99px) {
        @content;
    }
}
@mixin medium {
    @media (min-width: 960px) and (max-width: 1279.99px) {
        @content;
    }
}
@mixin large {
    @media (min-width: 1280px) and (max-width: 1799px) {
        @content;
    }
}
@mixin xlarge {
    @media (min-width: 1800px) and (max-width: 2559px) {
        @content;
    }
}
@mixin xxlarge {
    @media (min-width: 2560px) {
        @content;
    }
}