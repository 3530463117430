@import "../styles/variables.scss";

body {
    background: var(--body-background);
    // font-size: 14px;
    @include fontSize(12px, 18px, 480px, 1280px, 14px);
    color: white;
    line-height: 1.57;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // overflow: hidden;
  }
  h1 {
    // font-size: 18px;
    @include fontSize(16px, 18px, 480px, 1280px, 16px);
    color: var(--text-color);
    text-align: center;
  }
  h2 {
    // font-size: 16px;
    @include fontSize(14px, 20px, 480px, 1280px, 16px);
    color: var(--text-color);
  }
  p {
    // font-size: 14px;
    @include fontSize(12px, 18px, 480px, 1280px, 14px);
    margin: 12px 0px;
    line-height: 1.4;
    color: var(--text-color);
  }
  span, li {
    color: var(--text-color);
  }
  strong {
      font-weight: 600;
  }
  i {
    font-style: italic;
  }
  a {
    color:$light-color;
    text-decoration: none;
  }
  a:hover {
    color: var(--theme-hover-link-color);
  }
  svg, img {
      width: initial;
  }
  img {
    max-width: 100%;
  }
  .container {
    // width: 960px;
    width: 100%;
  }
  .left-column {
    width: 192px;
  }