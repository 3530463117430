.Toggler-label {
	display: inline-block;           
	position: relative;
	vertical-align: middle;
	font-size: 14px;
	user-select: none;	
    margin-top: 8px;
}
.Toggler-label .checkbox-Toggler {
	display: inline-block;	
	width: 36px;
	height: 14px;	
	border-radius: 20px;
	position: relative;
	top: 6px;			
	vertical-align: top;
	background: #9f9f9f;
	transition: .2s;
}
.Toggler-label .checkbox-Toggler:before {
	content: '';
	display: inline-block;	
	width: 20px;	
	height: 20px;
	position: absolute;
	top: -3px;
	left: -1px;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
	transition: .15s;		
}
.Toggler-label input[type=checkbox] {
	display: block;	
	width: 0;
	height: 0;	
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.Toggler-label input[type=checkbox]:checked + .checkbox-Toggler {
	background: #9ABEF7;
}
.Toggler-label input[type=checkbox]:checked + .checkbox-Toggler:before {
	background: #1a73e8;
	transform:translateX(18px);
}
 
/* Hover */
.Toggler-label input[type="checkbox"]:not(:disabled) + .checkbox-Toggler {
	cursor: pointer;
	border-color: rgba(0, 0, 0, .3);
}

.Toggler {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}