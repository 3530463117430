@import "../../../styles/variables.scss";

.prt-Modal {
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	background-color: var(--transparent-background);

	&__overlay {
		position: fixed;
		left: 0px;
		top: 0px;
		transform: translate(0px, 0px);
		z-index: 1501;
		transition: all 0s ease 0s;
	}
	&__container {
		// height: 75%;
		width: 66%;
		position: absolute;
		pointer-events: auto;
		background: var(--block-background);
		outline: 0;
		max-height: calc(100vh - 225px);
		overflow-y: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
    	transition: all 0s ease 0s;
		box-shadow: var(--block-shadow);
		border-radius: 8px;
	}
	&__content {
		position: relative;
		padding: 0;
		padding: 20px;
		// height: calc(100% - 156px)!important;
	}
	&__close {
		cursor: pointer;
	}
	&__title {
		font-size: 16px;
	}
	&__modal-top {
		border-bottom: none;
		color: var(--text-color);
		position: relative;
		padding: 2px 20px;
		min-height: 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #afb6c0;
	}
	&__modal-bottom {
		position: relative;
		z-index: 1;
		padding: 20px;
		overflow: visible;
		display: flex;
    	justify-content: flex-end;
	}
}
.prt-Modal__modal-bottom .dx-button {
    min-width: 100px;
}
.prt-Modal__modal-bottom .dx-toolbar-item {
    padding: 0 5px 0 0;
}
