@import '../../../styles/variables.scss';

.Tabs {
	position: relative;
	display: flex;
	justify-content: flex-start;
	margin-left: 20px;
    margin-right: 20px;
	&__active-bg {
		height: 100%;
		position: absolute;
		border-bottom: 3px solid $light-color;;
		margin-top: 2px;
		margin-left: 15px;
	}
	&__item {
		margin-left: 5px;
		position: relative;
		border: 0;
		border-bottom: 3px solid transparent;
		background: none;
		margin-right: 5px;
		@include fontSize(18px, 18px, 480px, 1280px, 18px);
		text-align: center;
		cursor: default;
		margin-top: 5px;
		padding-left: 20px;
		color: #FFFFFF;
    	cursor: pointer;
		&:hover {
			color: #FFFFFF;
		}
		&:active {
			color: #FFFFFF;
		}
		&--active {
			color: #FFFFFF;
		}
	}
}

.SubMenu {
	position: absolute;
	top: 52px; 
	background: var(--base-background);
	color: var(--text-color);
	padding: 18px 15px 15px 15px;
	text-align: left;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	box-shadow: var(--block-shadow);
	transform: translateY(-20px);
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	left: 14px;
	width: 135px;
	border-top: 3px solid transparent;
	&__item_sub {
		@include fontSize(14px, 14px, 480px, 1280px, 14px);
		color: #FFFFFF;
		cursor: pointer;
		&:hover {
			color: var(--theme-hover-link-color);
		}
		&--active {
			color: var(--theme-hover-link-color);
		}
	}
	li {
		line-height: 16px;
	}
	li:not(:last-child) {   
		margin-bottom: 10px;
	}
  }
