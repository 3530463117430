@import "./variables.scss";

:root[data-theme='MAIN'] {
    --base-background: #{$main-base-background};
    --base-color: #{$main-base-color};
	--body-background: #{$main-body-background};
	--header-background: #{$main-header-background};
    --block-background: #{$main-block-background};
    --content-background: #{$main-content-background};
    --head-background: #{$main-head-background};
    --accent-background: #{$main-accent-background};
    --line-color: #{$main-line-color};
    --link-color: #{$main-link-color};
    --text-color: #{$main-text-color};
    --text-color-table: #{$main-text-color-table};
    --text-color-table-head: #{$main-text-color-table-head};
    --icon-arrow: #{$main-icon-arrow};
    --block-shadow: #{$main-block-shadow};
    --light-border: #{$main-light-border};
    --accent-text: #{$main-accent-text};
    --transparent-background: #{$main-transparent-background};
    --icon-start-color: #{$main-icon-start-color};
    --icon-start-color-active: #{$main-icon-start-color-active};
    --icon-filter-color: #{$main-icon-filter-color};
    --btn-primary-bg: #{$main-btn-primary-bg};
    --btn-primary-color: #{$main-btn-primary-color};
    --btn-secondary-bg: #{$main-btn-secondary-bg};
    --btn-secondary-color: #{$main-btn-secondary-color};
    --btn-contour-bg: #{$main-btn-contour-bg};
    --btn-contour-color: #{$main-btn-contour-color};
    --btn-contour-gray-bg: #{$main-btn-contour-gray-bg};
    --btn-contour-gray-color: #{$main-btn-contour-gray-color};
}

:root[data-theme='DARK'] {
    --base-background: #{$dark-base-background};
    --base-color: #{$dark-base-color};
	--body-background: #{$dark-body-background};
	--header-background: #{$dark-header-background};
    --block-background: #{$dark-block-background};
    --content-background: #{$dark-content-background};
    --head-background: #{$dark-head-background};
    --accent-background: #{$dark-accent-background};
    --line-color: #{$dark-line-color};
    --link-color: #{$dark-link-color};
    --text-color: #{$dark-text-color};
    --text-color-table: #{$dark-text-color-table};
    --text-color-table-head: #{$dark-text-color-table-head};
    --icon-arrow: #{$dark-icon-arrow};
    --block-shadow: #{$dark-block-shadow};
    --light-border: #{$dark-light-border};
    --accent-text: #{$dark-accent-text};
    --transparent-background: #{$dark-transparent-background};
    --icon-start-color: #{$dark-icon-start-color};
    --icon-start-color-active: #{$dark-icon-start-color-active};
    --icon-filter-color: #{$dark-icon-filter-color};
    --btn-primary-bg: #{$dark-btn-primary-bg};
    --btn-primary-color: #{$dark-btn-primary-color};
    --btn-secondary-bg: #{$dark-btn-secondary-bg};
    --btn-secondary-color: #{$dark-btn-secondary-color};
    --btn-contour-bg: #{$dark-btn-contour-bg};
    --btn-contour-color: #{$dark-btn-contour-color};
    --btn-contour-gray-bg: #{$dark-btn-contour-gray-bg};
    --btn-contour-gray-color: #{$dark-btn-contour-gray-color};
}