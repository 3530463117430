@import "../../../styles/variables.scss";

.lang {
    width: 50px;
    height: 53px;
    display: flex;
    align-items: center;
    &__txt {
        display:inline-block;
        margin-left:5px;
        vertical-align:middle;
        @include fontSize(14px, 18px, 480px, 1280px, 16px);
    }
    &__item-opened {
        display: flex;
        align-items: center;
        justify-content: center;
        right: 10px;
        left: auto;
        top: 0;
        width: 24px;
        color: rgba(#C6C7CA, 0.7);
    
        &::before {
            position: absolute;
            top: 24px;
            content: "";
            background-image: var(--icon-arrow);
            display: block;
            width: 12px;
            height: 6px;
            transition: transform 0.2s;
            background-repeat: no-repeat;
        }
    
        &--opened::before {
            transform: rotate(-180deg);
        }
    }
}